.vertical-menu-sider {
  min-height: 100vh;
  position: fixed;
  left: 0;
  background-color: #001529;
  transition: width 0.3s ease; // Плавное изменение ширины при сворачивании
  overflow: auto;
}

.logo-container {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #001529;
}

.logo {
  height: 50px;
  transition: all 0.3s;
}
