.ping-results-container {
  padding: 20px;
}

.down-row {
  background-color: #ffebee; // Красный оттенок фона для строк с ошибками
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
